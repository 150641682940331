<template>
  <section class="store">
    <div class="wrap-maps">
      <GmapMap
        :center="centerMaps"
        :zoom="zoomMaps"
        map-type-id="terrain"
        class="maps" >

        <div
          v-for="store in storeMaps"
          :key="store.id" >
          <gmap-marker
            :position="store.latLong"
            :animation="2"
            :icon="store.iconMarker" >
          </gmap-marker>
        </div>

        <gmap-marker
          v-if="current.show"
          :position="current.position"
          :icon="current.iconMarker" >
        </gmap-marker>
      </GmapMap>
    </div>

    <div class="container">
      <div class="row justify-content-between head">
        <div class="col-12 title">
          <TitleHead title="Store" />
        </div>
<!-- this.current.isActive -->
        <div class="col-12 col-lg-4  btn-current-location">
          <button
            @click="clickCurrentLocation()"
            class="btn-current-location">
            <img src="@/assets/icons/ic-current-location.svg">
            <span>{{ textLocation }}</span>
          </button>
        </div>

        <div class="col-12 col-md-6 col-lg-5  select-city">
          <div class="row">
            <div class="col-5 title">
              <span>SELECT YOUR CITY</span>
            </div>

            <div class="col-7">
              <select
                v-model="filterCity" >
                <option
                  v-for="(city, i) in cities"
                  :key="i"
                  :value="city" >
                  {{ city }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-3  search-input">
          <input
            v-model="filterPostcode"
            type="text"
            placeholder="SEARCH BY city or zip" >
        </div>
      </div>

      <div v-if="stores.length !== 0">
        <div
          v-for="(city, i) in showCities"
          :key="i"
          class="row list-store" >
          <div
            v-if="city.toUpperCase() !== 'All'.toUpperCase() && isActiveStore"
            style="width: 100%;" >
            <div class="col-12 province">
              <h3>{{ city }}</h3>
            </div>

            <div class="col-12">
              <div class="row">
                <div
                  v-for="store in stores"
                  :key="store.id"
                  :class="store.status === 'active' && city === store.city ? 'col-12 col-md-6 col-lg-4 list' : ''" >
                  <div v-if="city === store.city && store.status === 'active'">
                    <div>
                      <div class="row">
                        <div class="col-12">
                          <h4>{{ store.name }}</h4>
                        </div>
                        <div class="col-12">
                          <p>{{ store.address }}</p>
                        </div>

                        <div class="col-12">
                          <div class="row justify-content-between">
                            <div class="col-7">
                              <div class="time">
                                <div class="box-time">
                                  <img src="@/assets/icons/ic-time.svg">
                                  <span>{{ store.time }}</span>
                                </div>
                                <!-- TODO: Open Day -->
                                <span class="open-day">{{ store.openDays }}</span>
                              </div>
                            </div>

                            <div class="col-5 link">
                              <a
                                :href="store.directUrl"
                                target="blank" >
                                <span>GET DIRECTION</span>
                                <img src="@/assets/icons/ic-right-arrow-forward.svg">
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="isComing && isComingStore" class="row list-coming">
          <div class="col-12">
            <div class="label">
              <p>COMING SOON</p>
            </div>
          </div>

          <!-- Coming -->
          <!-- :class="store.status === 'coming' ? 'col-12 col-md-6 col-lg-4 list' : ''" -->
          <div
            v-for="(store) in countdown"
            :key="store.id"
            class="col-12 col-md-6 col-lg-4 list" >
              <div class="box-countdown">
                  <p class="text">Store will open in:</p>
                  <div class="count">
                    <span>{{ store.countdown.days }}<span>Day</span></span>
                    <span>{{ store.countdown.hours }}<span>Hours</span></span>
                    <span>{{ store.countdown.minutes }}<span>Mins</span></span>
                    <span>{{ store.countdown.seconds }}<span>Sec</span></span>
                  </div>
              </div>
              <h4>{{ store.name }}</h4>
              <p>{{ store.address }}</p>
          </div>
        </div>
      </div>

      <div
        v-else
        class="empty" >
        <span>Empty</span>
      </div>
    </div>
  </section>
</template>

<script>
import head from './head'
import { mapState } from 'vuex'
import moment from 'moment'
const TitleHead = () => import('@/components/TitleHead')

export default {
  components: {
    TitleHead
  },

  head: head,

  data () {
    return {
      storeMaps: [],
      stores: [],
      cities: null,
      showCities: null,
      filterCity: '',
      filterPostcode: '',
      isComing: false,
      emptyStore: '',
      centerMaps: {
        lat: 13.7567046,
        lng: 100.499701
      },
      zoomMaps: 12,
      current: {
        isActive: false,
        show: false,
        position: null,
        iconMarker: require('@/assets/icons/ic-current-marker.png')
      },
      textLocation: 'Bangkok, Thailand',
      countCheck: 0,
      rawCountdown: [],
      countdown: [],
      isActiveStore: false,
      isComingStore: false
    }
  },

  computed: {
    ...mapState({
      rawStores: state => state.store.rawStores
    })
  },

  methods: {
    async mapStore (stores) {
      if (stores) {
        let arrCities = []
        let arrStores = []

        await stores.forEach((store, i) => {
          let now = moment()
          let willTime = moment(store.date_publish)
          let diff = moment.duration(willTime.diff(now))

          let status = null

          let iconMarker = ''

          if (diff._data.years <= 0 &&
            diff._data.months <= 0 &&
            diff._data.days <= 0 &&
            diff._data.hours <= 0 &&
            diff._data.minutes <= 0 &&
            diff._data.seconds <= 0) {
            status = 'active'
            this.isActiveStore = true

            if (!this.isComing) {
              this.isComing = false
            }

            iconMarker = require('@/assets/images/marker-cup.png')
          } else {
            status = 'coming'
            this.isComing = true
            this.isComingStore = true

            iconMarker = require('@/assets/images/marker-cup-coming.png')
          }

          let addressSplitlength = store.address.split(' ').length
          let city = store.address.split(' ')[addressSplitlength - 3]
          let postcode = store.address.split(' ')[addressSplitlength - 1]

          arrCities.push(city)

          let obj = {
            id: store.id,
            name: store.branch,
            address: store.address,
            city: city,
            postcode: postcode,
            latLong: {
              lat: parseFloat(store.lat_long.split(',')[0]),
              lng: parseFloat(store.lat_long.split(',')[1])
            },
            iconMarker: iconMarker,
            addressMaps: store.address_maps,
            time: store.time,
            openDays: store.day_open,
            directUrl: store.map_url,
            datePublish: store.date_publish,
            status: status
          }

          arrStores.push(obj)
        })

        let newArrCities = await [...new Set(arrCities)]

        this.filterCity = await 'All'
        this.cities = await newArrCities
        await this.cities.unshift('All')
        this.showCities = await newArrCities
        this.stores = await arrStores
      }
    },

    mapStoreMaps (stores) {
      if (stores) {
        stores.forEach((store, i) => {
          setTimeout(() => {
            this.storeMaps.push(store)
          }, i * 200)
        })
      }
    },

    filterCityStores (city) {
      if (city.toUpperCase() === 'All'.toUpperCase()) {
        this.mapStore(this.rawStores)
      } else {
        let arrStores = []
        let arrCities = []

        this.rawStores.forEach((store, i) => {
          let addressSplitlength = store.address.split(' ').length
          let citySplit = store.address.split(' ')[addressSplitlength - 3]
          let postcode = store.address.split(' ')[addressSplitlength - 1]

          if (citySplit.toUpperCase() === city.toUpperCase()) {
            arrCities.push(citySplit)

            let now = moment()
            let willTime = moment(store.date_publish)
            let diff = moment.duration(willTime.diff(now))

            let status = null

            let iconMarker = ''

            if (diff._data.years <= 0 &&
                diff._data.months <= 0 &&
                diff._data.days <= 0 &&
                diff._data.hours <= 0 &&
                diff._data.minutes <= 0 &&
                diff._data.seconds <= 0) {
              status = 'active'

              if (!this.isComing) {
                this.isComing = false
              }

              iconMarker = require('@/assets/images/marker-cup.png')
            } else {
              status = 'coming'
              this.isComing = true
              iconMarker = require('@/assets/images/marker-cup-coming.png')
            }

            let obj = {
              id: store.id,
              name: store.branch,
              address: store.address,
              city: citySplit,
              postcode: postcode,
              latLong: {
                lat: parseFloat(store.lat_long.split(',')[0]),
                lng: parseFloat(store.lat_long.split(',')[1])
              },
              iconMarker: iconMarker,
              addressMaps: store.address_maps,
              time: store.time,
              openDays: store.day_open,
              directUrl: store.map_url,
              datePublish: store.date_publish,
              status: status
            }

            arrStores.push(obj)
          }
        })

        let newArrCities = [...new Set(arrCities)]
        this.showCities = newArrCities
        this.stores = arrStores
      }
    },

    filterPostcodeStores (postcode) {
      if (postcode === '') {
        this.mapStore(this.rawStores)
      } else {
        this.isActiveStore = false
        this.isComingStore = false

        let arrStores = []
        let arrPostcode = []
        let arrCities = []
        let arrStatus = []

        this.rawStores.forEach((store, i) => {
          let addressSplitlength = store.address.split(' ').length
          let citySplit = store.address.split(' ')[addressSplitlength - 3]
          let postcodeSplit = store.address.split(' ')[addressSplitlength - 1]

          arrPostcode.push(postcodeSplit)

          // arrCities.push(citySplit)

          let now = moment()
          let willTime = moment(store.date_publish)
          let diff = moment.duration(willTime.diff(now))

          let status = null

          let iconMarker = ''

          if (diff._data.years <= 0 &&
            diff._data.months <= 0 &&
            diff._data.days <= 0 &&
            diff._data.hours <= 0 &&
            diff._data.minutes <= 0 &&
            diff._data.seconds <= 0) {
            status = 'active'

            if (!this.isComing) {
              this.isComing = false
            }

            iconMarker = require('@/assets/images/marker-cup.png')
          } else {
            status = 'coming'
            this.isComing = true
            iconMarker = require('@/assets/images/marker-cup-coming.png')
          }

          let obj = {
            id: store.id,
            name: store.branch,
            address: store.address,
            city: citySplit,
            postcode: postcodeSplit,
            latLong: {
              lat: parseFloat(store.lat_long.split(',')[0]),
              lng: parseFloat(store.lat_long.split(',')[1])
            },
            iconMarker: iconMarker,
            addressMaps: store.address_maps,
            time: store.time,
            openDays: store.day_open,
            directUrl: store.map_url,
            datePublish: store.date_publish,
            status: status
          }

          arrStores.push(obj)

          arrPostcode.filter(item => item)
        })

        let filterStores = arrStores.filter(item => item.address.replace(/\s/g, '').toLowerCase().includes(postcode.replace(/\s/g, '').toLowerCase()))

        filterStores.forEach(store => {
          if (store.status.toLowerCase() === 'active') {
            arrCities.push(store.city)
          }

          arrStatus.push(store.status)
        })

        let newArrCities = [...new Set(arrCities)]

        arrStatus.forEach(status => {
          if (status.toLowerCase() === 'active') {
            console.log(this.isActiveStore)
            this.isActiveStore = true
          }
          if (status.toLowerCase() === 'coming') {
            console.log(this.isComingStore)
            this.isComingStore = true
          }
        })

        this.showCities = newArrCities
        this.stores = filterStores

        if (filterStores.length === 0) {
          this.emptyStore = 'Empty'
          this.showCities = []
        }
      }
    },

    checkGeolocation () {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          const currentPosition = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          }

          this.textLocation = 'USE CURRENT LOCATION'
          this.current.isActive = true
          this.current.position = currentPosition
          // eslint-disable-next-line
        }, err => {
          console.log(`Error | ${err.message}`)
        })
      } else {
        this.textLocation = 'USE CURRENT LOCATION'
      }
    },

    clickCurrentLocation () {
      if (this.current.isActive) {
        this.current.show = true
        this.centerMaps = this.current.position
        this.zoomMaps = 16
      } else {
        this.zoomMaps = 14
        this.centerMaps = {
          lat: 13.7567046,
          lng: 100.499701
        }
      }
    }
  },

  watch: {
    rawStores (stores) {
      this.mapStore(stores)
    },

    stores (stores) {
      if (this.countCheck === 0) {
        setTimeout(() => {
          this.mapStoreMaps(stores)
        }, 1000)
      }

      this.countCheck++
    },

    filterCity (city) {
      this.isComing = false
      this.filterCityStores(city)
    },

    filterPostcode (postcode) {
      this.isComing = false
      this.filterCity = 'All'
      this.filterPostcodeStores(postcode)
    },

    rawCountdown (countdown) {
      if (countdown) {
        this.countdown = []

        countdown.forEach(countdown => {
          this.stores.forEach(store => {
            if (countdown.id === store.id) {
              let obj = {
                id: store.id,
                name: store.name,
                address: store.address,
                countdown: countdown
              }

              this.countdown.push(obj)
            }
          })
        })

        // console.log(this.countdown.countdown)
      }
    }
  },

  created () {
    this.$store.dispatch('store/getStore')
    this.checkGeolocation()
  },

  mounted () {
    setInterval(() => {
      if (this.stores && this.isComing) {
        this.rawCountdown = []

        this.stores.forEach(store => {
          if (store.status.toString() === 'coming') {
            let diffs = {
              id: store.id,
              days: 0,
              hours: 0,
              minutes: 0,
              seconds: 0
            }

            let now = moment()
            let willTime = moment(store.datePublish)

            let duration = moment.duration(willTime.diff(now))

            diffs.days = Math.trunc(duration.asDays())
            duration.subtract(moment.duration(diffs.days, 'days'))

            diffs.hours = Math.trunc(duration.hours())
            duration.subtract(moment.duration(diffs.hours, 'hours'))

            diffs.minutes = Math.trunc(duration.minutes())
            duration.subtract(moment.duration(diffs.minutes, 'minutes'))

            diffs.seconds = Math.trunc(duration.seconds())

            this.rawCountdown.push(diffs)
          }
        })
      }
    }, 1000)
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variable.scss';

.store {
  padding-top: 60px;

  .wrap-maps {
    .maps {
      width: 100%;
      height: 100vw;

      @media screen and (min-width: 768px) {
        height: 80vh;
      }
    }
  }

  .head {
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid #CCC;

    .title  {
      display: flex;
      justify-content: center;

      @media screen and (min-width: 768px) {
        justify-content: flex-start;
      }
    }

    .btn-current-location {
      display: flex;
      justify-content: center;
      margin: 10px 0;

      @media screen and (min-width: 1024px) {
        justify-content: flex-start;
      }

      button {
        display: flex;
        align-items: center;
        margin: 0;
        width: fit-content;
        border: none;
        background: none;

        img {
          height: 20px;
        }

        span {
          margin-left: 20px;
          width: 160px;
          font-family: 'DB Ozone X Med';
          text-align: left;
          font-size: 18px;
          color: $color-main;
        }
      }
    }

    .select-city {
      margin: 10px 0;

      .title {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        span {
          font-family: 'DB Ozone X Med';
          font-size: 16px;
          color: $color-main;
        }
      }

      select {
        outline: none;
        appearance: none;
        -webkit-padding-end: 30px !important;
        padding: 10px 0 10px 10px;
        width: 100%;
        border-radius: 0;
        border: none;
        background-repeat: no-repeat;
        background-image: url('../../assets/icons/ic-dropdown.svg');
        background-size: 10px;
        background-position: calc(100% - 10px) calc(100% - 20px);
        background-color: #EEE;
        font-family: 'DB Ozone X Med';
        font-size: 18px;
      }
    }

    .search-input {
      margin: 10px 0;

      input {
        outline: none;
        -webkit-padding-end: 35px !important;
        width: 100%;
        padding: 12px;
        border: none;
        background-repeat: no-repeat;
        background-image: url('../../assets/icons/ic-magnifying-glass.svg');
        background-size: 18px;
        background-position: calc(100% - 10px) center;
        background-color: #EEE;
        font-family: 'DB Ozone X Med';
        font-size: 16px;
        color: black;
      }
    }
  }

  .list-store {
    margin-top: 25px;
    margin-bottom: 25px;

    .province {
      // margin: 30px 0 0 0;

      h3 {
        font-family: 'DB Ozone X Bd';
        font-size: 40px;
        color: $color-main;
      }
    }

    .list {
      padding: 20px;

      h4 {
        font-family: 'DB Ozone X Med';
        font-size: 26px;
        color: $color-main;
      }

      p {
        margin-top: -6px;
        font-family: 'DB Ozone X Med';
        line-height: 1;
        font-size: 18px;
        color: black;
      }

      .time {
        display: flex;
        flex-direction: column;
        margin-top: 6px;

        .box-time {
          display: flex;

          img {
            margin-top: 4px;
            height: 8px;
          }

          span {
            margin-left: 4px;
            font-size: 12px;
            color: $color-main;
          }
        }

        .open-day {
          margin-top: -4px;
          margin-left: 12px;
          font-size: 11px;
          font-weight: 200;
          color: $color-main;
        }
      }

      .link {
        span {
          font-size: 12px;
          color: #3A68B1;
        }

        img {
          margin-left: 6px;
          height: 6px;
        }
      }
    }
  }

  .list-coming {
    margin-top: 50px;
    margin-bottom: 50px;

    .box-countdown {
      display: flex;
      align-items: center;

      .text {
        opacity: 1;
        padding-top: 10px;
        font-family: 'DB Ozone X Bd';
        font-size: 1rem;
      }

      .count {
        span {
          margin-left: 4px;
          font-size: 1rem;
          color: $color-main;

          span {
            margin-left: 4px;
            font-family: 'DB Ozone X';
            font-size: 0.5rem;
            color: black;
          }
        }
      }
    }

    .label {
      margin: 10px 0;
      padding: 2px 6px;
      width: 88px;
      height: 24px;
      border-radius: 4px;
      border: 1px solid $color-main;

      p {
        font-family: 'DB Ozone X Med';
        font-size: 14px;
        color: $color-main;
      }
    }

    .list {
      padding: 20px;

      h4 {
        opacity: 0.18;
        font-family: 'DB Ozone X Med';
        font-size: 26px;
        color: black;
      }

      p {
        opacity: 0.18;
        margin-top: -6px;
        font-family: 'DB Ozone X Med';
        line-height: 1;
        font-size: 18px;
        color: black;
      }
    }
  }
}

.hide {
  display: none;
}

.empty {
  opacity: 0.3;
  display: flex;
  justify-content: center;
  margin: 30px 0;
  padding: 20vh 0;
  width: 100%;
  border-radius: 50px;
  border: 1px dashed black;
}
</style>
