import url from '@/resources/_url'

const head = {
  title: {
    inner: 'สาขาของเรา TrueCoffee GO'
  },
  meta: [
    {
      id: 'description',
      name: 'description',
      content: 'TrueCoffee GO พร้อมให้บริการมากกว่า 10 สาขา สามารถเลือกดูได้ตาม location ที่ต้องการไปใช้บริการได้เลย พร้อมโปรแรงๆ Buy 2 Get 1 Free! ซื้อ 2 แก้ว ฟรี 1 แก้ว!'
    },
    {
      id: 'og:url',
      name: 'og:url',
      content: `${url.mainUrl}/promotion`
    },
    {
      id: 'og:title',
      name: 'og:title',
      content: 'สาขาของเรา TrueCoffee GO'
    },
    {
      id: 'og:description',
      name: 'og:description',
      content: 'TrueCoffee GO พร้อมให้บริการมากกว่า 10 สาขา สามารถเลือกดูได้ตาม location ที่ต้องการไปใช้บริการได้เลย พร้อมโปรแรงๆ Buy 2 Get 1 Free! ซื้อ 2 แก้ว ฟรี 1 แก้ว!'
    },
    {
      id: 'og:image',
      name: 'og:image',
      content: '/meta-image.png'
    }
  ],
  link: [
    {
      id: 'canonical',
      rel: 'canonical',
      href: `${url.mainUrl}/store`
    }
  ]
}

export default head
